import React, {useState} from 'react';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import LoadingButton from '@mui/lab/LoadingButton';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {styled} from "@mui/material";
import axios from "axios";

export default function Reports() {
    dayjs.extend(utc)
    const [fromDate, setFromDate] = useState(dayjs().startOf('month'));
    const [toDate, setToDate] = useState(dayjs().endOf('month'));
    const [loading, setLoading] = useState(false);

    const Image = styled("img")({
        maxWidth: '350px'
    });

    const Reports = styled("div")({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
    });

    const Dates = styled("div")({
        display: 'flex',
        flexDirection: 'row'
    });

    const Buttons = styled("div")({
        display: 'flex',
        flexDirection: 'column'
    });

    const StyledButton = styled(LoadingButton)({
        width: '150px',
        margin: '10px'
    });

    const StyledDatePicker = styled(DatePicker)({
        margin: '10px'
    });

    function onDownloadClick() {
        setLoading(true)
        const url = 'https://api.crossapp.crossjoin.pt/v1/pay/report/686944744?swapid=686947418&appid=686952259&starttime=' + fromDate.toISOString() + '&endtime=' + toDate.toISOString()
        axios({
            url: url,
            method: 'GET',
            responseType: "blob",
            headers: {Accept: "application/octet-stream"}
        }).then((response) => {
            // create file link in browser's memory
            // const href = URL.createObjectURL(new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
            const href = URL.createObjectURL(response.data);
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            const fileName = 'CrossApp_' + fromDate.format().substring(0, 10) + '_' + toDate.format().substring(0, 10) + '.xlsx';
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

            setLoading(false)
        });
    }

    return (
        <Reports>
            <Image src="logo.png" alt="CROSSJOIN SOLUTIONS"/>
            <Dates>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledDatePicker label="From"
                                      format="DD/MM/YYYY"
                                      value={fromDate}
                                      onChange={(value: any) => setFromDate(value)}/>
                    <StyledDatePicker label="To"
                                      format="DD/MM/YYYY"
                                      value={toDate}
                                      onChange={(value: any) => setToDate(value)}/>
                </LocalizationProvider>
            </Dates>
            <Buttons>
                <StyledButton variant="contained"
                              onClick={() => onDownloadClick()}
                              loading={loading}
                >Download</StyledButton>
            </Buttons>
        </Reports>
    );
};