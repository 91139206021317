import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Reports from "./Reports";
import {CssBaseline} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#ec1c2d',
        },
        mode: 'dark',
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Reports/>
        </ThemeProvider>
    );
}

export default App;
